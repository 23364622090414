<template>
  <v-overlay
      @click.native="hideOverlay"
      :opacity="opacity"
      :value="overlay">
    <div class="hide-button" v-if="loaded">
      <v-btn
          class="transparent"
          icon
          tile
          color="white"
          large
          @click="hideOverlay"
      >
        <v-icon large>$denied</v-icon>
      </v-btn>
    </div>
    <v-img
        :src="imgUrl"
        :lazy-src="`${imgUrl}&type=lazy`"
        max-height="80vh"
        max-width="80vh"
        @load="loaded = true"
    />
  </v-overlay>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';

export default {
  name: 'ExpandedImageOverlay',
  data: () => ({
    absolute: true,
    opacity: 0.85,
    overlay: false,
    imgUrl: '',
    loaded: false,
  }),
  created() {
    EventBus.$on('expandImage', (imgUrl) => {
      this.showOverlay(imgUrl.imgUrl);
    });
  },
  methods: {
    showOverlay(imgUrl) {
      this.loaded = false;
      this.overlay = true;
      this.imgUrl = imgUrl;
    },
    hideOverlay() {
      this.overlay = false;
    },
  },
};
</script>

<style>
.hide-button {
  position: absolute;
  z-index:  1;
  top:      0;
  right:    0;
}
</style>
