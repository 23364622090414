<template>
  <div :class="this.grid" class="pa-0">
    <div class="k-field-wrapper mb-2">
      <VFileInput ref="input"
                  v-bind="fieldProps"
                  v-on="$listeners"
                  :label="translatedLabel"
                  :rules="computedRules"
                  :value="value"
                  class="pr-2"
                  :multiple="multiple"/>
    </div>
    <v-responsive class="pr-2" :aspect-ratio="1" style="width: 100%">
      <v-sheet outlined
               width="100%"
               height="100%"
               :style="{borderColor: primaryColor, minHeight: minHeight}"
               role="button"
               @drop.prevent="onDrop"
               @dragenter.prevent
               @dragleave.prevent="glow = false"
               @dragover.prevent="glow = true"
               @click.stop="inputClick"
               class="d-flex justify-center"
               :class="[glow ? 'glow' : null, preview ? 'align-start' : 'align-center']">
        <div class="pa-1 overflow-hidden">
          <k-btn v-if="!preview" @click.stop="inputClick">
            {{ $t('actions.upload') }}
            <v-icon right>{{ icon }}</v-icon>
          </k-btn>
          <VImg ref="img" max-width="100%" max-height="100%" v-else :src="preview"/>
        </div>
      </v-sheet>
    </v-responsive>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'KFileField',
  components: { KBtn },
  mixins: [Field],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Object, File, Array],
    },
    minHeight: {
      type: [Number, String],
      default: '32px',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    preview: '',
    previewType: '',
    glow: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        // only show preview when only one file is allowed
       if (value && !this.multiple) this.fetchPreview(value[0]);
      },
    },
  },
  computed: {
    icon() {
      if (!this.previewType) {
        return '$upload';
      }
      return this.getIconByType(this.previewType);
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
  },
  methods: {
    onDrop(event) {
      // create array from fileList object
      const fileArray = Array.from(event.dataTransfer.files);
      this.glow = false;
      // only send first item (in an array) when multiple is false, to avoid multiple files being send to input.
      this.$emit('change', this.multiple ? fileArray : [fileArray[0]]);
    },
    inputClick(...args) {
      return this.$refs?.input?.$refs.input.click(...args);
    },
    fetchPreview(file) {
      if (!file || !file.type) {
        this.preview = '';
        this.previewType = '';
        return;
      }
      if (file.type.match('image.*')) {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            this.preview = reader.result;
            this.previewType = '';
          }, false);
          reader.readAsDataURL(file);
          return;
        }
        this.preview = file.url;
        return;
      }
      this.preview = '';
      this.previewType = file.type;
    },
    getIconByType(type) {
      if (type.match('video/*')) {
        return '$fileVideo';
      } else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/msword') {
        return '$fileWord';
      } else if (type === 'application/pdf') {
        return '$filePdf';
      }
      return '$fileUnknown';
    },
  },
};
</script>

<style scoped>
.file-preview {
  height:   100px;
  overflow: hidden;
  width:    100px;
}

.glow {
  box-shadow: inset 0 0 24px #cccccc
}
</style>
