import {
  faArrowsAltV,
  faBars,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faDotCircle,
  faDownload,
  faExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faMinus,
  faMinusSquare,
  faPaperclip,
  faPencilAlt,
  faPlus,
  faSortUp,
  faStar,
  faStarHalf,
  faStepBackward,
  faStepForward,
  faSync,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faChevronLeft,
  faChevronRight,
  faCircle,
  faSquare,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';

const icons = [
  faMinus,
  faPlus,
  faPaperclip,
  faArrowsAltV,
  faStepForward,
  faStepBackward,
  faSync,
  faStar,
  faStarHalf,
  faPencilAlt,
  faCircle,
  faDotCircle,
  faCaretDown,
  faBars,
  faChevronDown,
  faSortUp,
  faMinusSquare,
  faSquare,
  faCheckSquare,
  faChevronRight,
  faChevronLeft,
  faExclamationTriangle,
  faExclamation,
  faInfoCircle,
  faCheckCircle,
  faTimes,
  faCheck,
  faTrashAlt,
  faDownload,
];

/*vuetify default icons*/
const identifiers = {
  complete: ['fas', 'check'],
  cancel: ['far', 'times'],
  close: ['far', 'times'],
  delete: ['fas', 'trash-alt'],
  download: ['fas', 'download'],
  clear: ['far', 'times'],
  success: ['fas', 'check-circle'],
  info: ['fas', 'info-circle'],
  warning: ['fas', 'exclamation'],
  error: ['fas', 'exclamation-triangle'],
  prev: ['far', 'chevron-left'],
  next: ['far', 'chevron-right'],
  checkboxOn: ['fas', 'check-square'],
  checkboxOff: ['far', 'square'],
  checkboxIndeterminate: ['fas', 'minus-square'],
  delimiter: ['fas', 'circle'],
  sort: ['fas', 'sort-up'],
  expand: ['fas', 'chevron-down'],
  menu: ['fas', 'bars'],
  subgroup: ['fas', 'caret-down'],
  dropdown: ['fas', 'caret-down'],
  radioOn: ['fas', 'dot-circle'],
  radioOff: ['far', 'circle'],
  edit: ['fas', 'pencil-alt'],
  ratingEmpty: ['fas', 'star'],
  ratingFull: ['fas', 'star'],
  ratingHalf: ['fas', 'star-half'],
  loading: ['fas', 'sync'],
  first: ['fas', 'step-backward'],
  last: ['fas', 'step-forward'],
  unfold: ['fas', 'arrows-alt-v'],
  file: ['fas', 'paperclip'],
  plus: ['fas', 'plus'],
  minus: ['fas', 'minus'],
  checkRoundChecked: ['fas', 'check-circle'],
  checkRoundUnchecked: ['far', 'circle']
};

export default {
  icons,
  identifiers,
};
