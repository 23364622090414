<template>
  <v-app>
    <div v-if="loaded" class="fill-height background">
      <RouterView/>
    </div>
    <v-overlay v-else>
      <VProgressCircular indeterminate size="64"/>
    </v-overlay>
    <TheConfirmationDialog/>
    <TheSnackbarQueueHandler/>
    <CreateTicketForm/>
    <ExpandedImageOverlay/>
  </v-app>
</template>

<script lang="js">
import TheConfirmationDialog from '@/components/TheConfirmationDialog.vue';
import TheSnackbarQueueHandler from '@/components/TheSnackbarQueueHandler.vue';
import { switchLanguage } from '@/plugins/i18n.js';
import { mapActions, mapGetters } from 'vuex';
import CreateTicketForm from '@/modules/tickets/components/CreateTicketForm.vue';
import ExpandedImageOverlay from '@/modules/tickets/components/ExpandedImageOverlay.vue';

export default {
  name: 'App',
  components: {
    ExpandedImageOverlay,
    TheSnackbarQueueHandler,
    CreateTicketForm,
    TheConfirmationDialog,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters('authorisation', {
      isLoggedIn: 'isLoggedIn',
      locale: 'getLocale',
    }),
  },
  watch: {
    colors() {
      this.setVuetifyColors(this.colors);
    },
    locale: {
      handler(val) {
        if (val) switchLanguage(this.locale);
      },
      immediate: true,
    },
  },
  async created() {
    /**
     * If the user is already logged in, in this session they never fetched it's profile so we need to do that now
     */
    await Promise.all([
      ...this.isLoggedIn ? [this.fetchProfile()] : [],
    ]);

    this.loaded = true;
  },
  methods: {
    ...mapActions({
      fetchProfile: 'authorisation/fetchProfile',
    }),
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
</style>
