import Vue from 'vue'
import { amountInCart } from '@/modules/shoppingCart/api';

export default {
  namespaced: true,
  state: {
    inCart: {}
  },
  mutations: {
    setStoreAmount(state, { data, storeId, }) {
      Vue.set(state.inCart, storeId, data)
    },
  },
  actions: {
    async fetchAmountInCart({ commit }, storeId) {
      const { data: { data } } = await amountInCart(storeId);
      commit('setStoreAmount', { data, storeId, });
    },
  },
  getters: {
    hasItemsInCart(state, getters, rootState, rootGetters) {
      const storeId = rootGetters['settings/getCurrentStoreId'];
      return state.inCart?.[storeId]?.numberOfProducts > 0;
    },
  },
};
