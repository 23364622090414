import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (id, ...args) => getPaginated(`store/${id}/shopping-cart`, ...args);

export const create = (id, values) => post(`store/${id}/shopping-cart/submit`, values);

export const update = (values) => put(`store/${values.id}/shopping-cart`, values);

export const remove = ({ id }) => destroy(`order-line/${id}`);

export const amountInCart = (id) => get(`store/${id}/shopping-cart/info`);

export const getAddons = (id) => get(`order-line/${id}/add-on`);

export const saveAddonSelection = (id, values) => put(`order-line/${id}/add-on`, values);

export const getStoreInformation = (id) => get(`store/${id}/address`);
