import { post, get, destroy } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const show = (clientId, ticketId) => get(`client/${clientId}/ticket/${ticketId}`);

export const create = (clientId, values) => post(`client/${clientId}/ticket`, values);

export const index = (clientId, ...args) => getPaginated(`client/${clientId}/ticket`, ...args);

export const storeIndex = (clientId, storeId, ...args) => getPaginated(`store/${storeId}/ticket`, ...args);

export const autocomplete = (storeId, ...args) =>
  getPaginated(`store/${storeId}/order/autocomplete`, ...args);

export const uploadImage = (clientId, ticketId, file) => post(`client/${clientId}/ticket/${ticketId}/file`, { file });

export const deleteImage = (clientId, ticketId, imageId) => destroy(`client/${clientId}/ticket/${ticketId}/file/${imageId}`);

export const addComment = (ticketId, comment) => post(`ticket/${ticketId}/comment`, { comment });

export const getComments = (ticketId) => get(`ticket/${ticketId}/comment`);

