import {
  faBook,
  faBox,
  faCheckCircle,
  faClipboardList,
  faComments,
  faCube,
  faFolderTree,
  faShoppingCart,
  faSignOutAlt,
  faStore,
  faCartArrowDown,
  faThumbsUp,
  faTimesCircle,
  faTimes,
  faCheck,
  faQuestionCircle,
  faClipboardListCheck,
  faExclamationTriangle,
  faSearchPlus,
  faCircle,
  faBars,
  faTicketAlt,
  faUserTag,
  faGlobeEurope,
  faBell
} from '@fortawesome/pro-solid-svg-icons';

const icons = [
  faBox,
  faCheckCircle,
  faTimesCircle,
  faFolderTree,
  faSignOutAlt,
  faShoppingCart,
  faStore,
  faBook,
  faCube,
  faClipboardList,
  faCartArrowDown,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faCheck,
  faComments,
  faQuestionCircle,
  faClipboardListCheck,
  faExclamationTriangle,
  faSearchPlus,
  faCircle,
  faBars,
  faUserTag,
  faGlobeEurope,
  faBell
];

/*project specific icons*/
const identifiers = {
  enabled: ['fas', 'check-circle'],
  disabled: ['fas', 'times-circle'],
  product: ['fas', 'box'],
  productCategories: ['fas', 'folder-tree'],
  signOff: ['fas', 'sign-out-alt'],
  shoppingCart: ['fas', 'shopping-cart'],
  store: ['fas', 'store'],
  catalog: ['fas', 'book'],
  configurator: ['fas', 'cube'],
  myOrders: ['fas','clipboard-list'],
  orders: ['fas','clipboard-list-check'],
  approvals: ['fas','thumbs-up'],
  comments: ['fas', 'comments'],
  putInCart: ['fas', 'cart-arrow-down'],
  locationDetails: ['fas', 'store'],

  approved: ['fas', 'check'],
  unknown: ['fas', 'question-circle'],
  denied: ['fas', 'times'],

  circle: ['fas', 'circle'],

  warning: ['fas', 'exclamation-triangle'],
  enlarge: ['fas', 'search-plus'],
  hamburgerMenu:['fas', 'bars'],

  favourite: ['fas', 'star'],

  tickets: ['fas','ticket-alt'],
  roles: ['fas','user-tag'],
  regions: ['fas','globe-europe'],
  notifications:['fas','bell']
};

export default {
  icons,
  identifiers,
};
