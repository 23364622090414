<template>
  <div>
    <v-dialog
      v-model="dialog"
      content-class="create-ticket-dialog"
      transition="dialog-top-transition"
      max-width="800"
      persistent
    >
      <v-card
        tile
        class="pa-3"
      >
        <v-form
          ref="form"
          v-model="isValid"
          @submit.prevent="createTicket"
        >
          <k-field-group language-prefix="ticket.fields">
            <v-card-title>
              <h3 class="text-h3 secondary--text text--darken-3">
                {{ $t('ticket.dialogTitle').toUpperCase() }}
              </h3>
              <VSpacer/>
              <v-btn
                color="secondary"
                outlined
                tile
                @click="closeDialog"
              >
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn
                color="secondary"
                class="ml-3"
                tile
                depressed
                :loading="isLoading"
                type="submit"
              >
                {{ $t('ticket.submit') }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="6" class="pr-2">
                  <h3 class="text-h3 secondary--text text--darken-3">
                    {{ $t('ticket.subject').toUpperCase() }}
                  </h3>
                  <KRadioGroup
                    v-model="form.subjectId"
                    :items="issues"
                    field="subject"
                    required
                  />
                  <KTextField
                    v-if="form.subjectId === issuesEnum.OTHER"
                    v-model="form.subject"
                    field="otherSubject"
                    :required="form.subjectId === issuesEnum.OTHER"
                  />
                  <KTextarea
                    v-model="form.description"
                    field="description"
                    required
                  />
                </v-col>
                <v-col cols="6" class="pl-2">
                  <h3 class="text-h3 secondary--text text--darken-3">
                    {{ $t('ticket.location').toUpperCase() }}
                  </h3>
                  <KRadioGroup
                    v-model="form.locationType"
                    :items="locations"
                    field="location"
                    required
                  />
                  <OrdersAutocomplete
                    :return-object="false"
                    v-model="form.relatedOrderId"
                    clearable
                    hide-details
                    field="order"
                    item-text="description"
                    item-value="id"
                  />
                  <KFileField
                    v-model="form.images"
                    :hint="$t('ticket.fields.fileHint')"
                    field="file"
                    accept=".png, .jpg, .jpeg"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </k-field-group>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KFileField from '@/components/crud/fields/KFileField';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import ticketIssues from '@/application/enums/ticketIssues.js';
import ticketLocations from '@/application/enums/ticketLocations.js';
import OrdersAutocomplete from '@/modules/orders/components/OrdersAutocomplete.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import { Ticket } from '@/application/models/Ticket.js';
import { mapGetters } from 'vuex';
import { create } from '@/modules/tickets/api/ticket.js';

export default {
  name: 'CreateTicketForm',
  components: {
    OrdersAutocomplete,
    KRadioGroup,
    KTextarea,
    KFileField,
    KFieldGroup,
    KTextField
  },
  data: () => ({
    dialog: false,
    form: new Ticket(),
    isValid: false,
    isLoading: false,
    issueCategories: [
      "Missing in transit",
      "Visible packaging damage",
      "Hidden packaging damage",
      "Damaged during installation"
    ],
    table: null
  }),
  created() {
    EventBus.$on('createTicket', (obj) => {
      this.table = obj.table;
      this.dialog = true;
    });
  },
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
    issuesEnum() {
      return ticketIssues;
    },
    issues() {
      return Object.keys(ticketIssues).map(type => {
        return {
          value: ticketIssues[type],
          text: this.$t(`ticket.issueCategories.${type}`),
        };
      });
    },
    locations() {
      return Object.keys(ticketLocations).map(type => {
        return {
          value: ticketLocations[type],
          text: this.$t(`ticket.locations.${type}`),
        };
      });
    },

  },
  watch: {
    'form.subjectId': {
      handler(newVal) {
        if(newVal === ticketIssues.OTHER){
          this.form.subject = '';
          return;
        }

        this.form.subject = this.issueCategories[newVal - 1];
      },
    },
  },
  methods: {
    async createTicket() {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.form.storeId = this.storeId;
      const response = await create(this.clientId, this.form);
      if (response.status === 201) {
        this.dialog = false;
        this.table.reload();
      }
      this.isLoading = false;
    },
    closeDialog(){
      this.$refs.form.reset();
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss">
.create-ticket-dialog {
  align-self: flex-start;
  margin-top: 0;
}
</style>
