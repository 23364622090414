const initialState = ({ floorplannerLoaded: false });

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    mutateFloorplannerLoaded(state) {
      state.floorplannerLoaded = true;
    },
  },
  actions: {
    setFloorplannerLoaded({ commit }) {
      commit('mutateFloorplannerLoaded');
    },
  },
  getters: {
    isFloorplannerLoaded: state => state.floorplannerLoaded,
  },
};
