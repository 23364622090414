import store from '@/application/store';

export default function (to, from, next) {
  if (!store.getters['authorisation/isLoggedIn']) {
    next({ name: 'login' });
    return;
  }
  if (!store.getters['authorisation/isAdmin']) {
    next({ name: 'home' });
    return;
  }
  next();
}
