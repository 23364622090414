import { productList } from '@/modules/catalog/api';
import AuthorisationGuard from '@/router/guards/AuthorisationGuard.js';

export default {
  path: '',
  beforeEnter: AuthorisationGuard,
  component: () => import('@/templates/Default'),
  children: [
    {
      path: 'home',
      name: 'home',
      component: () => import('@/modules/home/views/Home.vue'),
    },
    {
      path: 'shopping-cart',
      name: 'shoppingCart',
      component: () => import('@/modules/shoppingCart/views/Table.vue'),
    },
    {
      path: 'checkout',
      name: 'checkout',
      component: () => import('@/modules/shoppingCart/views/OrderCheckout.vue'),
    },
    {
      path: 'catalog',
      name: 'catalog.category',
      component: () => import('@/modules/catalog/views/CategoryOverview.vue'),
    },
    {
      path: 'catalog/wall-items/:categoryId',
      name: 'catalog.product',
      props: {
        indexRequest: productList
      },
      component: () => import('@/modules/catalog/views/ProductOverview.vue'),
    },
    {
      path: 'approvals',
      name: 'approvals.index',
      component: () => import('@/modules/approvals/view/Table.vue'),
    },
    {
      path: 'approvals/:approvalId',
      name: 'approvals.show',
      component: () => import('@/modules/approvals/view/Show.vue'),
    },
    {
      path: 'my-orders',
      name: 'my-orders.index',
      component: () => import('@/modules/orders/views/MyOrdersList.vue'),
    },
    {
      path: 'my-tickets',
      name: 'my-tickets.index',
      component: () => import('@/modules/tickets/views/MyTicketsList.vue'),
    },
    {
      path: 'my-tickets/:ticketId',
      name: 'my-tickets.show',
      component: () => import('@/modules/tickets/views/MyTicketsDetail.vue'),
    },
    {
      path: 'regions',
      name: 'regions.index',
      component: () => import('@/modules/regions/views/RegionsList')
    },
    {
      path: 'notifications',
      name: 'notifications.index',
      component: () => import('@/modules/notifications/views/NotificationsList.vue')
    },
    {
      path: 'roles',
      name: 'roles.index',
      component: () => import('@/modules/roles/views/RolesList')
    },
    {
      path: 'tickets',
      name: 'tickets.index',
      component: () => import('@/modules/tickets/views/TicketsList.vue'),
    },
    {
      path: 'tickets/:ticketId',
      name: 'tickets.show',
      component: () => import('@/modules/tickets/views/TicketsDetail.vue'),
    },
    {
      path: 'my-orders/:orderId',
      name: 'my-orders.show',
      component: () => import('@/modules/orders/views/MyOrdersDetail.vue')
    },
    {
      path: 'orders',
      name: 'orders.index',
      component: () => import('@/modules/orders/views/OrdersList.vue'),
    },
    {
      path: 'orders/:orderId',
      name: 'orders.show',
      component: () => import('@/modules/orders/views/OrdersDetail.vue')
    },
    {
      path: 'configurator',
      name: 'configurator.index',
      component: () => import('@/modules/configurator/views/index.vue')
    },
    {
      path: 'location-details',
      name: 'locationDetails',
      component: () => import('@/modules/locationDetails/views/LocationDetails.vue')
    },
  ],
};
