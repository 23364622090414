<template>
  <v-dialog v-model="showDialog" width="500px">
    <v-sheet class="pa-2">
      <v-row class="ma-0">
        <div class="title">{{ title }}</div>
        <VSpacer/>
        <v-btn icon small @click="handleClose">
          <v-icon color="black">$close</v-icon>
        </v-btn>
      </v-row>
      <v-row class="ma-3">
        <v-col style="max-width: 40px">
          <v-icon color="warning" :size="25">$warning</v-icon>
        </v-col>
        <v-col class="body-1">{{ body }}</v-col>
      </v-row>
      <v-row class="ma-0">
        <VSpacer/>
        <v-btn outlined color="primary" v-if="showCancel" @click="handleCancel" class="mr-2">{{ cancelText }}</v-btn>
        <v-btn color="primary" @click="handleContinue">{{ confirmText }}</v-btn>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';

export default {
  name: 'TheConfirmationDialog',
  data() {
    return {
      showDialog: false,
      title: '',
      body: '',
      showCancel: true,
      confirmCallback: null,
      confirmText: '',
      cancelCallback: null,
      cancelText: '',
      closeCallback: null,
    };
  },
  created() {
    EventBus.$on('confirm', (params) => {
      this.title = params.title;
      this.body = params.body;
      this.showCancel = params.showCancel ?? true;
      this.confirmCallback = params?.confirmCallback;
      this.cancelCallback = params?.cancelCallback;
      this.closeCallback = params?.closeCallback;
      this.showDialog = true;
      this.confirmText = params.confirmText || this.$t('actions.continue');
      this.cancelText = params.cancelText || this.$t('actions.cancel');
    });
  },
  methods: {
    handleContinue() {
      this.showDialog = false;

      if (typeof this.confirmCallback === 'function') {
        this.confirmCallback();
      }
    },
    handleCancel() {
      this.showDialog = false;

      if (typeof this.cancelCallback === 'function') {
        this.cancelCallback();
      }
    },
    handleClose() {
      this.showDialog = false;

      if (typeof this.closeCallback === 'function') {
        this.closeCallback();
      }
    },
  },
};
</script>
