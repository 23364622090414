import Model from './model';
import { Address } from '@/application/models/Address.js';

export class Ticket extends Model {
  subjectId = null;
  subject = '';
  description = '';
  locationType = '';
  relatedOrderId = null;
  storeId = null;
  images = [];

  mapForRequest() {
    return { ...this };
  }
}

export class TicketDetail extends Model {
  id = 0;
  address = new Address();
  subject = '';
  description = '';
  relatedOrder = {};
  contactInfo = {};
  ticketStatusId = 0;
  creationDate = '';
  images = [];
  isLoaded = false;

  mapResponse(data) {
    return super.mapResponse(data);
  }
}
