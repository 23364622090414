import { get, post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const categoryList = () => get(`product-category`);

export const categoryDetails = (categoryId) => get(`product-category/${categoryId}`)

export const productList = (categoryId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`product-category/${categoryId}/product`, page, perPage, search, sortBy, descending, params);

export const addToShoppingCart = (storeId, values) => post(`store/${storeId}/shopping-cart/order-line`, values)
