<template>
  <KPaginatedAutocomplete ref="paginated" v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/tickets/api/ticket.js';
import { mapGetters } from 'vuex';

export default {
  name: 'OrdersAutocomplete',
  components: { KPaginatedAutocomplete },
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
  },
  watch: {
    storeId() {
      if (!this.$refs || !this.$refs.paginated) {
        return;
      }
      this.$refs.paginated.refresh();
    },
  },
  methods: {
    async autocomplete(...args) {
      const response = await autocomplete(this.storeId, ...args);
      return response;
    }
  },
};
</script>
