export default {
  namespaced: true,
  state: {
    storeId: null,
    floorplannerId: null,
    crudLayoutViews: {},
  },
  mutations: {
    setCurrentStoreId(state, id) {
      state.storeId = id;
    },
    setFloorplannerId(state, id) {
      state.floorplannerId = id;
    },
    setCrudLayoutView(state, {
      routeName,
      selectedView,
    }) {
      state.crudLayoutViews[routeName] = selectedView;
    },
  },
  actions: {
    updateCurrentStoreId({commit, dispatch}, id){
      commit('setCurrentStoreId', id)
      dispatch('shoppingCart/fetchAmountInCart', id, {root: true})
    },
    updateFloorplannerId({commit}, id){
      commit('setFloorplannerId', id)
    }
  },
  getters: {
    getCurrentStoreId: (state) => state.storeId,
    getFloorplannerId: (state) => state.floorplannerId,
    getSelectedCrudLayoutView: (state) => (routeName) => {
      return state.crudLayoutViews[routeName];
    },
  },
};
